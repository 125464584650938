import React from 'react'
import PropTypes from 'prop-types'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'

const TwoColumn = ({ gridItems }) => {
  return (    
    <div className="columns is-multiline">       
        {gridItems.map((item, i) => (          
          <div key={i} className={`column is-12 two-column row-${i} `}>          
            <section className="section columns">            
              <div className="has-text-centered column is-6">
                <div className="two-column__image-wrapper image__wrapper"
                  style={{
                    maxWidth: '450px',
                    width: '100%',
                    display: 'inline-block',
                  }}
                > 
                  <PreviewCompatibleImage imageInfo={item} />
                </div>
              </div>
              <div className="column is-6 two-column--text">
                <div className={`body-${i}`}>
                  <h2 style={{fontSize: '1.5em'}}>{item.header}</h2>
                  <div className="two-column--text-body"
                    dangerouslySetInnerHTML={{
                      __html: item.text,
                    }}
                  />                  
                </div>
              </div>                      
            </section>                    
          </div>
        ))}
    </div>
  )
}


TwoColumn.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
}

export default TwoColumn
